<template>
  <lf-card class="max-w-screen-md py-6">
    <template v-slot>
      <loader :isLoading="isSubmitting" />
      <form @submit.prevent="onSubmit">
        <div class="my-2 space-y-2">
          <lf-h3>{{ $t("ORGANIZATION.CLOSE_API.TITLE") }}</lf-h3>
          <span v-if="!(isFundingAdvisor || isClientFundingAdvisor)">
            {{ $t("ORGANIZATION.CLOSE_API.DESCRIPTION") }}
          </span>
          <lf-input
            :placeholder="$t('ORGANIZATION.CLOSE_API.API_KEY')"
            :value="closeio_api_key"
            name="api_key"
            type="text"
            class="py-3"
            :disabled="isFundingAdvisor || isClientFundingAdvisor"
          />
          <access-control :roles="NOT_FA_ROLE_GROUP">
            <div
              class="flex justify-end items-center min-w-full border-t pt-6 pr-5"
            >
              <primary-button type="submit" :disabled="isSubmitting">
                {{ $t("COMMON.SAVE_CHANGES") }}
              </primary-button>
            </div>
          </access-control>
        </div>
      </form>
    </template>
  </lf-card>
</template>

<script>
import { computed, reactive } from "vue";
import { useStore } from "vuex";
import { useForm } from "vee-validate";
import { dispatchAction } from "@/helpers/vee-validate";
import * as Yup from "yup";

import LfCard from "@/components/ui/LfCard.vue";
import { useAuth } from "@/hooks/auth";
import { NOT_FA_ROLE_GROUP } from "@/helpers/constants";

const validationSchema = Yup.object().shape({
  api_key: Yup.string()
});

export default {
  components: {
    LfCard
  },
  setup() {
    const { getters } = useStore();
    const { isFundingAdvisor, isClientFundingAdvisor } = useAuth();
    const closeio_api_key = computed(
      () => getters["clients/active"].closeio_api_key
    );

    const initialValues = reactive({
      api_key: closeio_api_key.value ?? ""
    });

    const { handleSubmit, isSubmitting } = useForm({
      initialValues,
      validationSchema
    });

    const onSubmit = handleSubmit(async (values, actions) => {
      if (!values.api_key) {
        delete values.api_key;
      }
      try {
        await dispatchAction(values, actions, "clients/updateCloseApiKey");
      } catch {
        //
      }
    });

    return {
      onSubmit,
      isSubmitting,
      closeio_api_key,
      NOT_FA_ROLE_GROUP,
      isFundingAdvisor,
      isClientFundingAdvisor
    };
  }
};
</script>
